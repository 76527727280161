// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_q9 d_bC";
export var storyRowWrapper = "r_hv d_hv d_bJ";
export var storyLeftWrapper = "r_rb d_by d_bN";
export var storyWrapperFull = "r_rc d_cC";
export var storyWrapperFullLeft = "r_nL d_cC d_by d_bN d_bC";
export var contentWrapper = "r_px d_hw";
export var storyLeftWrapperCenter = "r_rd d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "r_rf d_hC";
export var storyHeader = "r_rg d_hB d_v d_cr";
export var storyHeaderCenter = "r_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "r_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "r_rh d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "r_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "r_rj d_ff d_Y";
export var imageWrapperFull = "r_rk d_v d_G d_bd d_Y";