import React from 'react';
import clsx from 'clsx';

import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';
import * as styles from '../styles.module.css';

const ImageElement = ({
  item,
  section,
  elementIndex,
  subIndex,
  space,
  colors = [],
  images,
  pagePathList,
  articlePathList,
  filePathList,
  categoryPathList,
}) => {
  let sizes = '100vw';
  const column = section.data[elementIndex]?.data?.[subIndex];
  if (column?.columnCount) {
    const width = column.columnCount / 0.12;
    sizes = `(min-width: 992px) ${width}vw, 100vw`;
  } else if (item.content.width) {
    sizes = `${item.content.width}vw`;
  }

  return (
    <div
      className={clsx(subIndex == null && 'col-10', subIndex != null && item?.type !== 'ICON' && styles.width100)}
      style={{
        marginBottom: space ? `${space}px` : undefined,
        marginTop: space ? `${space}px` : undefined,
        width: item?.content?.icon?.align && subIndex != null ? '100%' : undefined,
      }}
    >
      <div className={subIndex == null ? styles.customImageWrapper : undefined}>
        <CustomSectionMedia
          sizes={sizes}
          sectionId={section._id}
          data={item.content}
          colors={colors}
          align={item?.content?.icon?.align ?? section?.styles?.align ?? 'Left'}
          images={images}
          pagePathList={pagePathList}
          articlePathList={articlePathList}
          filePathList={filePathList}
          categoryPathList={categoryPathList}
        />
      </div>
    </div>
  );
};

export default ImageElement;
