// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pn";
export var articleText = "m_pp d_dt d_cr";
export var header = "m_pq d_Y d_v";
export var headerImageWrapper = "m_pr d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "m_ps d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "m_pt d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "m_pv d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "m_pw d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "m_px d_v";
export var dateTag = "m_py d_cd d_cq d_dp d_v";
export var icon1 = "m_pz d_cN";
export var icon2 = "m_pB d_cN d_cH";
export var tagBtn = "m_pC d_b0 d_cC d_dn d_dz";
export var headerText = "m_pD d_v d_cd d_cr d_bV";
export var center = "m_pF d_dv";
export var videoIframeStyle = "m_pG d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "m_pH d_cr d_Y";
export var articleImageWrapperIcon = "m_pJ d_cr d_Y";
export var articleRow = "m_pK d_bC";
export var quoteWrapper = "m_gN d_bB d_bN d_cr";
export var quoteBar = "m_pL d_G";
export var quoteText = "m_pM";
export var authorBox = "m_pN d_v";
export var authorRow = "m_pP d_bC d_bV d_cx";
export var separator = "m_pQ d_v";
export var line = "m_fj d_v d_fj d_cX d_cx";
export var authorImage = "m_lm d_b6 d_Y d_v d_cs";
export var authorText = "m_pR d_cg d_cs";
export var masonryImageWrapper = "m_pS";
export var bottomSeparator = "m_pT d_v d_cx";
export var linksWrapper = "m_pV d_cg d_dt";
export var comments = "m_pW d_bC d_cx";
export var sharing = "m_pX d_by d_bC d_bH";
export var shareText = "m_pY d_v d_dv";
export var icon = "m_pZ";
export var customRow = "m_p0 d_bC d_Y";
export var text = "m_p1";
export var SubtitleSmall = "m_p2";
export var SubtitleNormal = "m_p3";
export var SubtitleLarge = "m_p4";