import React from 'react';
import HTMLParser from 'html-react-parser';
import { format } from 'date-fns/esm';
import Cookies from 'universal-cookie';
import clsx from 'clsx';

import Lightbox from '../../../../Lightbox';
import NavHead from '../../../../NavHead';
import SectionMedia from '../../LayoutComponents/SectionMedia';
import LayoutContainer from '../../LayoutContainer';
import Image from '../../../../Image';
import { constructLink, createPreviewPath, formColor } from '../../../../../helper';
import CookieWarning from '../../../../CookieWarning';
import BackToTopButton from '../../../../TabletView/BackToTopButton';
import { InfoRowHeightContext } from '../../../../NavigationBar/InfoRowHeightContext';
import getNavHeight from '../../../../../helpers/getNavHeight';
import removeCookies from '../../../../../helpers/removeCookies';
import { StoreProvider } from '../../../../../helpers/Store';
import SeparatorElement from '../../LayoutElements/SeparatorElement';
import ButtonElement from '../../LayoutElements/ButtonElement';
import MapElement from '../../LayoutElements/MapElement';
import QuoteElement from '../../LayoutElements/QuoteElement';
import GalleryElement from '../../LayoutElements/GalleryElement';
import VideoElement from '../../LayoutElements/VideoElement';
import ImageElement from '../../LayoutElements/ImageElement';
import TextElement from '../../LayoutElements/TextElement';
import RowElement from '../../LayoutElements/RowElement';

import * as styles from './styles.module.css';

let IntersectionObserver;
if (typeof window !== 'undefined') {
  if (window.IntersectionObserver) IntersectionObserver = window.IntersectionObserver;
  else IntersectionObserver = require('intersection-observer'); // eslint-disable-line global-require
}

const cookies = new Cookies();

class Article extends React.Component {
  constructor(props) {
    super(props);

    cookies.get('cookiesAccepted');
    const { themeData, article, section, logo } = this.props.pageContext;

    const images = [];
    if (article) {
      article.data.forEach((c) => {
        if (c.type === 'GALLERY' && c.lightbox === true) {
          c.data.forEach((img) => {
            images.push(img);
          });
        }
      });
    }

    let previewSrc;
    let img;

    if (article && article.image.id) img = article.image.id;
    else if (article && article.articleLayout.heroImage.id) img = article.articleLayout.heroImage.id;
    else if (props.pageContext.seo && props.pageContext.seo.defaultPreview) img = props.pageContext.seo.defaultPreview;

    let imgData;
    if (img) {
      props.pageContext.images.some((i) => {
        let found = false;
        if (i._id.toString() === img || i.path === img) {
          imgData = i;
          found = true;
        }

        return found;
      });
    }

    if (imgData && imgData.path) previewSrc = createPreviewPath(imgData);

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const color0 = {
      color: themeData.colors[0],
    };

    const nav = props.pageContext.navigation;
    const { overlay } = nav.styles;

    let logoHeight;
    if (logo && logo.active) logoHeight = logo.logoHeight !== undefined ? logo.logoHeight : 60;
    else {
      let lineHeight = 1.5;
      let size = 38;
      if (themeData && themeData.typography && themeData.typography.logo) {
        ({ lineHeight } = themeData.typography.logo);
        size =
          themeData.typography.logo.fontSize === 'Large'
            ? 50
            : themeData.typography.logo.fontSize === 'Small'
            ? 30
            : size;
      }

      logoHeight = size * lineHeight;
    }

    let linkHeight = 34;
    if (themeData && themeData.typography && themeData.typography.navigation) {
      linkHeight =
        themeData.typography.navigation.fontSize === 'Small'
          ? 30
          : themeData.typography.navigation.fontSize === 'Large'
          ? 40
          : linkHeight;
    }

    this.state = {
      section,
      article,
      photoIndex: 0,
      isOpen: false,
      images,
      themeHeadingStyle,
      color0,
      overlay,
      isScrolling: false,
      matches: null,
      previewSrc,
      logoHeight,
      linkHeight,
    };

    this.sentinel = React.createRef();
  }

  componentDidMount() {
    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.styles.fixed_top) {
      const elem = this.sentinel.current;
      if (elem) {
        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);
      }
    }

    if (this.state.article.fbComments && window.FB) {
      window.FB.XFBML.parse();
    }
  }

  componentWillUnmount() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches);
    }
  }

  updateMatches = () => {
    this.setState({
      matches: this.mediaQueryList.matches,
    });
  };

  handleScroll = (entries) => {
    if (this.props.pageContext.navigation.styles.fixed_top) {
      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });
    }
  };

  handleImageClick = (event) => {
    const photoIndex = this.state.images.findIndex((image) => image.id === event.target.dataset.imageid);

    if (photoIndex > -1) {
      this.setState({
        photoIndex,
        isOpen: true,
      });
    }
  };

  handleCloseClick = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });
  };

  handleMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length,
    });
  };

  documentReady = (callbackFunction) => {
    if (typeof document !== 'undefined') {
      if (document.readyState !== 'loading') {
        callbackFunction();
      } else {
        document.addEventListener('DOMContentLoaded', callbackFunction);
      }
    }
  };

  createText = (item, index, type, componentIndex = null, subIndex, space) => {
    return (
      <TextElement
        item={item}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        type={type}
        parent="article"
        themeData={this.props.pageContext.themeData}
      />
    );
  };

  createImage = (item, elementIndex, componentIndex, subIndex, space) => {
    if (!item) return null;

    return (
      <ImageElement
        item={item}
        section={this.state.article}
        subIndex={subIndex}
        elementIndex={elementIndex}
        space={space}
        colors={this.props.pageContext.themeData?.colors}
        images={this.props.pageContext.images}
        pagePathList={this.props.pageContext.pagePathList}
        articlePathList={this.props.pageContext.articlePathList}
        filePathList={this.props.pageContext.filePathList}
        categoryPathList={this.props.pageContext.categoryPathList}
      />
    );
  };

  createVideo = (item, index, componentIndex, subIndex, space) => {
    return <VideoElement item={item} subIndex={subIndex} space={space} />;
  };

  createGallery = (gllr, componentIndex, subIndex, subSubIndex, space) => {
    return (
      <GalleryElement
        gllr={gllr}
        section={this.state.article}
        componentIndex={componentIndex}
        subIndex={subIndex}
        subSubIndex={subSubIndex}
        space={space}
        colors={this.props.pageContext.themeData?.colors}
        onImageClick={this.handleImageClick}
        images={this.props.pageContext.images}
      />
    );
  };

  createQuote = (item, index, componentIndex, subIndex, space) => {
    return (
      <QuoteElement
        item={item}
        index={index}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        colors={this.props.pageContext.themeData?.colors}
        createText={this.createText}
      />
    );
  };

  createSeparator = (item, index, componentIndex, subIndex, space) => {
    return (
      <SeparatorElement
        item={item}
        section={this.state.article}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        colors={this.props.pageContext.themeData?.colors}
      />
    );
  };

  createButton = (item, index, componentIndex, subIndex, space) => {
    return (
      <ButtonElement
        item={item}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        themeData={this.props.pageContext.themeData}
        buttons={this.props.pageContext.buttons}
        pagePathList={this.props.pageContext.pagePathList}
        articlePathList={this.props.pageContext.articlePathList}
        filePathList={this.props.pageContext.filePathList}
        categoryPathList={this.props.pageContext.categoryPathList}
      />
    );
  };

  createMap = (item, index, componentIndex, subIndex, space) => {
    return (
      <MapElement
        item={item}
        section={this.state.article}
        index={index}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        name={this.props.pageContext.themeData?.name}
      />
    );
  };

  createHeader = (clr) => {
    const navHeight = getNavHeight(
      this.context,
      this.state.logoHeight,
      this.state.linkHeight,
      this.props.pageContext.navigation.styles.layout,
      this.state.isScrolling && this.props.pageContext.navigation.styles.fixed_top,
    );

    const style = {};
    style.paddingTop = navHeight;
    let color = { color: clr || '#000000' };

    if (this.state.article.articleLayout.heroActive === true) {
      style.paddingBottom = 80;
      style.paddingTop = this.state.overlay === true ? navHeight + 120 : 120;
      color = clr ? color : { color: '#ffffff' };
    } else {
      style.marginBottom = 0;
      style.paddingTop = 40;
      if (this.state.overlay) {
        style.paddingTop = navHeight + 40;
      }
    }

    let pub;
    if (this.state.article.articleLayout.dateActive === true) {
      pub = format(new Date(this.state.article.pubDate), 'd.M.yyyy');
    }

    const defaultStyle = this.props.pageContext.themeData?.typography?.default;
    let tdStyle = {};
    if (defaultStyle) {
      tdStyle = {
        fontWeight: defaultStyle.weight,
        fontFamily: defaultStyle.name,
        fontSize: '14px',
      };
    }

    let dateTag;
    if (pub !== undefined || this.state.article.tags !== undefined) {
      dateTag = (
        <div className={styles.dateTag} style={color}>
          {pub !== undefined && (
            <>
              <i className={`entypo icon-calendar ${styles.icon1}`} />
              <span style={tdStyle}>{pub}</span>
            </>
          )}
          {this.state.article.articleLayout.tagsActive && this.state.article.tags?.length > 0 && (
            <>
              <i className={`entypo icon-mouse ${styles.icon2}`} />
              <span>
                {this.state.article.tags?.map((tag, idx) => {
                  let text = ', ';
                  if (idx === this.state.article.tags.length - 1) {
                    text = '';
                  }

                  return (
                    <span
                      style={{ ...tdStyle, color: clr || this.state.color0?.color }}
                      key={`${this.state.article._id}_TagSpan_${this.state.article.tags[idx]}`}
                    >
                      {tag}
                      {text}
                    </span>
                  );
                })}
              </span>
            </>
          )}
        </div>
      );
    }

    const size = this.state.article.articleLayout.heroImage.size || 'cover';
    const position = this.state.article.articleLayout.heroImage.position || 'center center';
    let id;
    if (this.state.article.articleLayout.heroActive === true) {
      if (
        this.state.article.articleLayout.articleImage &&
        (this.state.article.image.id || this.state.article.image.CDNLink)
      )
        ({ id } = this.state.article.image);
      else if (
        !this.state.article.articleLayout.articleImage &&
        (this.state.article.articleLayout.heroImage.id || this.state.article.articleLayout.heroImage.CDNLink)
      )
        ({ id } = this.state.article.articleLayout.heroImage);
    }

    let image;
    if (id !== undefined) {
      image = (
        <div className={styles.headerImageWrapper}>
          <Image
            id={id}
            sizes="100vw"
            size={size}
            position={position}
            alt="Background"
            imageStyleName="sectionBackgroundImageFull"
            images={this.props.pageContext.images}
          />
        </div>
      );
    }

    let overlay;
    if (
      this.state.article.articleLayout.overlay &&
      (this.state.article.articleLayout.overlay.color || this.state.article.articleLayout.overlay.opacity >= 0)
    ) {
      const oColor = this.state.article.articleLayout.overlay.color || '#000';
      const oOpacity =
        this.state.article.articleLayout.overlay.opacity >= 0 ? this.state.article.articleLayout.overlay.opacity : 0.7;

      overlay = formColor({ solid: oColor }, undefined, oOpacity, undefined, this.props.pageContext.themeData.colors);
    }

    const hColor = clr ? { color: clr } : color;
    const text = (
      <div className={`row ${styles.articleRow}`}>
        <div className={this.state.article.articleLayout.heroActive === true ? 'col-10' : 'col-10 col-lg-8'}>
          {dateTag}
          <div className={styles.headerText}>
            <h1 style={hColor}>{HTMLParser(this.state.article.title)}</h1>
          </div>
          {this.state.article.articleLayout.excerptActive === true && (
            <div className={styles.headerText}>
              <span
                className={styles[`Subtitle${this.props.pageContext.themeData.typography.heading.fontSize}`]}
                style={{ ...this.state.themeHeadingStyle, fontWeight: 'normal', ...hColor }}
              >
                {HTMLParser(this.state.article.excerpt)}
              </span>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div
        key={`${this.state.article._id}_${this.state.article._id}_Header`}
        className={`${styles.header} ${
          this.state.article.articleLayout.heroActive === true ? ` ${styles.center}` : ''
        }`}
        style={style}
      >
        {image}
        {this.state.article.articleLayout.heroActive === true && (
          <div className={styles[`headerGradient${overlay ? 'Overlay' : ''}`]} style={overlay} />
        )}
        <div className={styles[`headerContentWrapper${!this.state.article.articleLayout.heroActive ? 'Alt' : ''}`]}>
          <div className={`container ${styles.contentWrapper}`}>{text}</div>
        </div>
      </div>
    );
  };

  createAuthorBox = (auth, isSharing, clr) => {
    const author = this.state.section.styles.authors.find((a) => a._id === auth);

    let box;
    if (author !== undefined) {
      const links = [];
      if (author.links.length > 0) {
        author.links.forEach((l, i) => {
          if (l.active === true && l.image.icon !== null) {
            const { link } = constructLink(
              l.image.linkObj,
              this.props.pageContext.pagePathList,
              this.props.pageContext.articlePathList,
              this.props.pageContext.categoryPathList,
            );

            let tab;
            if (l.image.linkObj) tab = l.image.linkObj.openLinkInNewTab;
            const target = tab ? '_blank' : '_self';
            const rel = tab ? 'noopener noreferrer' : '';
            const newLink = (
              <a
                key={`${this.state.article._id}_ArticleMother_Owner_Link_${i}`}
                href={link}
                target={target}
                rel={rel}
                draggable="false"
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(newLink);
          }

          return null;
        });
      }

      const color = clr ? { color: clr } : undefined;
      box = (
        <div key={`${this.state.article._id}_Article_AuthorBox`} className={styles.authorBox}>
          <div className={styles[isSharing ? 'bottomSeparator' : 'line']} />
          <div className={`row ${styles.authorRow}`}>
            <div className="col-10 col-sm-3">
              {author.image !== '' && (
                <div className={styles.authorImage}>
                  <SectionMedia
                    mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                    wrapperStyleName="imageContent3"
                    imageStyleName=""
                    iconStyleName=""
                    sizes="25vw"
                    src={author.image.CDNLink ? author.image.CDNLink : `${process.env.IMAGES_CDN}/${author.image.src}`}
                    alt={author.image.alt}
                    data={author.image}
                    images={this.props.pageContext.images}
                    pagePathList={this.props.pageContext.pagePathList}
                    articlePathList={this.props.pageContext.articlePathList}
                    filePathList={this.props.pageContext.filePathList}
                    categoryPathList={this.props.pageContext.categoryPathList}
                    colors={this.props.pageContext.themeData.colors}
                  />
                </div>
              )}
            </div>
            <div className="col-10 col-sm-5">
              {author.name !== '' && (
                <div>
                  <h4 className={styles.authorText} style={color}>
                    {author.name}
                  </h4>
                </div>
              )}
              {author.desc !== '' && (
                <div>
                  <span className={styles.authorText}>{HTMLParser(author.desc)}</span>
                </div>
              )}
              {links.length > 0 && <div className={styles.linksWrapper}>{links}</div>}
            </div>
          </div>
        </div>
      );
    }

    return box;
  };

  createSharing = (sharing, href, clr) => {
    let style;
    const { colors } = this.props.pageContext.themeData;
    const type =
      this.state.article.sharingType && this.state.article.sharingType !== 'default'
        ? this.state.article.sharingType
        : sharing.type;
    switch (type) {
      case 'black':
        style = { color: '#000' };
        break;
      case 'white':
        style = { color: '#FFF' };
        break;
      case 'theme':
        style = { color: formColor({ solid: 'COLOR_0' }, false, 1, null, colors).backgroundColor };
        break;
      default:
        style = {
          color: formColor({ solid: sharing.shareStyle.color }, false, sharing.shareStyle.opacity, null, colors)
            .backgroundColor,
        };
    }

    const color = clr ? { color: clr } : {};
    return (
      <div key={`${this.state.article._id}_Article_Sharing`} className={clsx('col-8', styles.sharing)}>
        {sharing.shareHeading.active && sharing.shareHeading.text && (
          <div className={styles.shareText}>
            <h6 style={{ ...color, ...{ marginBlockStart: 0, marginBlockEnd: 0 } }}>
              {HTMLParser(sharing.shareHeading.text)}
            </h6>
          </div>
        )}
        <div>
          {['facebook', 'twitter', 'linkedin', 'whatsapp'].map((s) => {
            let icon;
            if (sharing[s]) {
              let url;
              switch (s) {
                case 'facebook':
                  url = `https://www.facebook.com/share.php?u=${href}`;
                  break;
                case 'twitter':
                  url = `https://twitter.com/intent/tweet?url=${href}`;
                  break;
                case 'linkedin':
                  url = `https://www.linkedin.com/sharing/share-offsite/?url=${href}`;
                  break;
                default:
                  url = `whatsapp://send?text=${href}`;
              }
              icon = (
                <a
                  key={s}
                  data-action={s === 'whatsapp' ? 'share/whatsapp/share' : undefined}
                  target="popup"
                  href={url}
                  onClick={
                    typeof window !== 'undefined'
                      ? (e) => {
                          e.preventDefault();
                          window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                        }
                      : undefined
                  }
                >
                  <i
                    className={clsx('entypo', `icon-${s === 'whatsapp' ? s : `${s}-squared`}`, styles.icon)}
                    style={style}
                    aria-hidden="true"
                  />
                </a>
              );
            }
            return icon;
          })}
        </div>
      </div>
    );
  };

  handleCookieClick = () => {
    if (typeof document !== 'undefined') {
      removeCookies();
      this.setState({ cookiePrev: this.state.cookieState, cookieState: !this.state.cookieState });
    }
  };

  getElement = (item, index, componentIndex, subIndex, space) => {
    let elem;
    if (item?.type === 'HEADINGS/HEADING-TWO') {
      elem = this.createText(item, index, 'subtitle', componentIndex, subIndex, space);
    } else if (item?.type === 'PARAGRAPH/PARAGRAPH') {
      elem = this.createText(item, index, 'paragraph', componentIndex, subIndex, space);
    } else if (item?.type === 'PARAGRAPH/QUOTE') {
      elem = this.createQuote(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'IMAGE' || item?.type === 'ICON') {
      elem = this.createImage(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'VIDEO') {
      elem = this.createVideo(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'GALLERY') {
      elem = this.createGallery(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'BUTTON') {
      elem = this.createButton(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'SEPARATOR') {
      elem = this.createSeparator(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'MAP') {
      elem = this.createMap(item, index, componentIndex, subIndex, space);
    }

    return elem;
  };

  render() {
    const { pageContext } = this.props;
    const { fbComments, share, articleLayout } = this.state.article;
    const href = `https://${pageContext.idn}${this.state.article.link.target}`;
    const fontColorData = articleLayout?.fontColor?.color ? articleLayout.fontColor : { color: '#000000', opacity: 1 };
    const headerColorData = articleLayout?.headerColor ? articleLayout.headerColor : { color: '#000000', opacity: 1 };
    const color = formColor(
      { solid: fontColorData.color },
      false,
      fontColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    ).backgroundColor;
    const hColor = formColor(
      { solid: headerColorData.color },
      false,
      headerColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    ).backgroundColor;

    let ready = false;
    if (typeof document !== 'undefined') {
      this.documentReady(() => {
        ready = true;
      });
    }

    let header;
    let article;
    let isSharing = false;
    let authorActive = false;

    if (this.state.section !== undefined && this.state.article !== undefined) {
      article = [];
      header = this.createHeader(articleLayout?.headerColor && hColor);

      this.state.article.data.forEach((item, index) => {
        if (index - 1 >= 0 && this.state.article.data[index - 1].type !== 'HEADINGS/HEADING-TWO') {
          const add = <div key={`${this.state.article._id}_article_separator_${index}`} className={styles.separator} />;

          article.push(add);
        }

        let elem;
        if (item?.type === 'ROW') {
          elem = (
            <RowElement
              key={`${this.state.article._id}_Row_${index}`}
              item={item}
              section={this.state.article}
              index={index}
              getElement={this.getElement}
            />
          );
        } else {
          elem = this.getElement(item, index);
          if (!elem) {
            if (pageContext.sharing && pageContext.sharing.active && share) {
              isSharing = true;
              article.push(this.createSharing(pageContext.sharing, href, articleLayout?.fontColor && color));
            }
            if (item.active === true) {
              elem = this.createAuthorBox(item.author, isSharing, articleLayout?.fontColor && color);
              authorActive = true;
            }
          } else {
            elem = (
              <div key={`${this.state.article._id}_Row_${index}`} className={clsx('row', styles.customRow)}>
                {elem}
              </div>
            );
          }
        }

        article.push(elem);
      });
    }

    const backgroundColorData = this.state.article.articleLayout.backgroundColor || { solid: '#ffffff', opacity: 1 };

    const { backgroundColor } = formColor(
      backgroundColorData,
      false,
      backgroundColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    );

    const commentsVisible = fbComments && pageContext.comments;

    return (
      <StoreProvider language={pageContext.metaLanguage}>
        <div ref={this.sentinel} />
        <div id="modal-root" />
        <NavHead
          isScrolling={this.state.isScrolling}
          pageContext={pageContext}
          previewSrc={this.state.previewSrc}
          metaTitle={this.state.article ? this.state.article.metaTitle : undefined}
          metaDescription={this.state.article ? this.state.article.metaDescription : undefined}
          title={pageContext.sanitizedTitle}
          desc={pageContext.sanitizedExcerpt}
          comments={fbComments ? pageContext.comments : null}
          href={href}
        />
        <div className="article" style={{ color, backgroundColor }}>
          {header}
          <div className="container" style={{ marginBottom: !authorActive && !commentsVisible ? 40 : null }}>
            {pageContext.backToTopButton && pageContext.backToTopButton.active && (
              <BackToTopButton colour={pageContext.backToTopButton.colour} />
            )}
            {this.state.images.length > 0 && (
              <Lightbox
                mainImageId={this.state.images[this.state.photoIndex].id}
                nextImageId={this.state.images[(this.state.photoIndex + 1) % this.state.images.length].id}
                prevImageId={
                  this.state.images[(this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length]
                    .id
                }
                onCloseRequest={this.handleCloseClick}
                onMovePrevRequest={this.handleMovePrevRequest}
                onMoveNextRequest={this.handleMoveNextRequest}
                visible={this.state.isOpen}
                images={pageContext.images}
              />
            )}
            {article}
            <div className={`row ${styles.articleRow}`}>
              {commentsVisible && (
                <>
                  <div className={styles[authorActive || !isSharing ? 'line' : 'bottomSeparator']} />
                  <div className={`col-10 col-sm-8 ${styles.comments}`}>
                    {pageContext.comments.text && (
                      <div>
                        <h4 style={articleLayout?.fontColor ? { color } : undefined}>{pageContext.comments.text}</h4>
                      </div>
                    )}
                    <div
                      className="fb-comments"
                      data-lazy="true"
                      data-numposts={pageContext.comments.numComments}
                      data-width="100%"
                      data-order-by={pageContext.comments.sortBy}
                      data-href={href}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {pageContext.footer && (
          <LayoutContainer
            pageContext={pageContext}
            key={`Layout_${pageContext.footer._id}`}
            section={pageContext.footer}
            matches={this.state.matches}
            onCookieClick={this.handleCookieClick}
          />
        )}
        {pageContext.cookie?.active === true &&
          (cookies?.cookies?.cookiesAccepted === undefined || this.state.cookieState !== this.state.cookiePrev) &&
          ready === true && (
            <CookieWarning
              themeData={pageContext.themeData}
              cookie={pageContext.cookie}
              pagePathList={pageContext.pagePathList}
              articlePathList={pageContext.articlePathList}
              categoryPathList={pageContext.categoryPathList}
              buttons={pageContext.buttons}
              images={pageContext.images}
              trackers={pageContext.seo.trackers}
              id={pageContext.page._id}
              onCookieClick={() => this.setState({ cookieState: false, cookiePrev: false })}
              show={this.state.cookieState !== this.state.cookiePrev}
            />
          )}
      </StoreProvider>
    );
  }
}

Article.contextType = InfoRowHeightContext;

export default Article;
