// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qh d_fn d_bF d_dt";
export var alignLeft = "p_qj d_fn d_bF d_dt";
export var alignDiscCenter = "p_qk d_fp d_bC d_dv";
export var alignCenter = "p_bN d_fp d_bC d_dv";
export var alignDiscRight = "p_ql d_fq d_bG d_dw";
export var alignRight = "p_qm d_fq d_bG d_dw";
export var footerContainer = "p_qn d_dV d_bV";
export var footerContainerFull = "p_qp d_dS d_bV";
export var footerHeader = "p_kc d_kc";
export var footerTextWrapper = "p_qq d_v";
export var footerDisclaimerWrapper = "p_kk d_kk d_cj";
export var badgeWrapper = "p_qr d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "p_qs undefined";
export var footerDisclaimerLeft = "p_qt undefined";
export var verticalTop = "p_qv d_by d_bH d_bM d_bK";
export var firstWide = "p_qw";
export var disclaimer = "p_qx d_by d_bH";
export var socialDisclaimer = "p_qy";
export var left = "p_qz";
export var wide = "p_qB d_cx";
export var right = "p_qC d_bJ";
export var line = "p_fj d_fk d_ct";
export var badgeDisclaimer = "p_qD d_bB d_bN d_bH";
export var badgeContainer = "p_qF d_by d_bG d_bN";
export var badge = "p_qG";
export var padding = "p_qH d_c6";
export var end = "p_qJ d_bG";
export var linkWrapper = "p_qK d_dz";
export var link = "p_mz d_dz";
export var colWrapper = "p_qL d_cw";
export var consent = "p_f d_f d_bB d_bN";
export var media = "p_qM d_bw d_dx";
export var itemRight = "p_qN";
export var itemLeft = "p_qP";
export var itemCenter = "p_qQ";
export var exceptionWeight = "p_qR s_rN";