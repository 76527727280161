const slugify = require('slugify');

const getSectionSlug = (page, sectionId) => {
  let sectionSlug;

  const sec = page?.sections?.find((s) => s?._id === sectionId);
  if (sec) {
    sectionSlug = slugify(sec.title, { lower: true, strict: true });

    const sectionsWithSameName = page.sections.filter((s) => s.title === sec.title);
    if (sectionsWithSameName.length > 1) {
      sectionSlug += `-${sectionsWithSameName.findIndex((s) => s._id === sectionId) + 1}`;
    }
  }

  return sectionSlug;
};

module.exports = getSectionSlug;
