// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qS d_gs d_cr";
export var heroHeaderCenter = "q_gt d_gt d_cr d_dv";
export var heroHeaderRight = "q_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "q_qT d_gp d_cv";
export var heroParagraphCenter = "q_gq d_gq d_cv d_dv";
export var heroParagraphRight = "q_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "q_qV d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "q_qW d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "q_qX d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "q_qY d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "q_qZ d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "q_q0 s_q0";
export var heroExceptionNormal = "q_q1 s_q1";
export var heroExceptionLarge = "q_q2 s_q2";
export var Title1Small = "q_q3 s_q3 s_rl s_rm";
export var Title1Normal = "q_q4 s_q4 s_rl s_rn";
export var Title1Large = "q_q5 s_q5 s_rl s_rp";
export var BodySmall = "q_q6 s_q6 s_rl s_rF";
export var BodyNormal = "q_q7 s_q7 s_rl s_rG";
export var BodyLarge = "q_q8 s_q8 s_rl s_rH";