// extracted by mini-css-extract-plugin
export var carouselContainer = "v_p7 d_v d_G d_bd d_Y";
export var carouselContainerCards = "v_sQ v_p7 d_v d_G d_bd d_Y";
export var carouselContainerSides = "v_sR d_v d_G d_Y";
export var prevCarouselItem = "v_sS d_v d_G d_Z d_j";
export var prevCarouselItemL = "v_sT v_sS d_v d_G d_Z d_j";
export var moveInFromLeft = "v_sV";
export var prevCarouselItemR = "v_sW v_sS d_v d_G d_Z d_j";
export var moveInFromRight = "v_sX";
export var selectedCarouselItem = "v_sY d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "v_sZ v_sY d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "v_s0 v_sY d_v d_G d_Y d_bd";
export var nextCarouselItem = "v_s1 d_v d_G d_Z d_j";
export var nextCarouselItemL = "v_s2 v_s1 d_v d_G d_Z d_j";
export var nextCarouselItemR = "v_s3 v_s1 d_v d_G d_Z d_j";
export var arrowContainer = "v_s4 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "v_s5 v_s4 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "v_s6 v_s5 v_s4 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "v_s7 v_s4 d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "v_s8";
export var nextArrowContainerHidden = "v_s9 v_s7 v_s4 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "v_kF d_Z";
export var prevArrow = "v_tb v_kF d_Z";
export var nextArrow = "v_tc v_kF d_Z";
export var carouselIndicatorContainer = "v_td d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "v_tf d_v d_by d_bD";
export var carouselText = "v_tg d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "v_th v_tg d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "v_tj d_b6";
export var carouselIndicator = "v_tk v_tj d_b6";
export var carouselIndicatorSelected = "v_tl v_tj d_b6";
export var arrowsContainerTopRight = "v_tm d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "v_tn d_Z d_bk d_bB";
export var arrowsContainerSides = "v_tp d_Z d_bk d_bB";
export var smallArrowsBase = "v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "v_tr v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "v_ts v_tr v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "v_tt v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "v_tv v_tt v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "v_pZ";
export var iconSmall = "v_tw";
export var multipleWrapper = "v_tx d_bB d_bD d_bd";
export var multipleImage = "v_ty d_bB";
export var sidesPrevContainer = "v_tz v_tr v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "v_tB v_tr v_tq d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";