// extracted by mini-css-extract-plugin
export var noMargin = "n_p5 d_cb";
export var carouselCol = "n_p6 d_cC";
export var carouselWrapper = "n_k5 d_k5 d_by d_bC d_bN d_v d_G";
export var carouselImageWrapper = "n_k6 d_k6 d_G d_v";
export var carouselContainer = "n_p7 d_cC";
export var carouselContainerFull = "n_p8 d_dS";
export var carouselImg = "n_mM d_k7 d_v d_G d_bQ";
export var imageTextWrapperLeft = "n_k0 d_k0 d_kZ d_Z d_bS";
export var imageTextWrapperCenter = "n_k1 d_k1 d_kZ d_Z d_bS";
export var imageTextWrapperRight = "n_k2 d_k2 d_kZ d_Z d_bS";
export var imageTextWrapperHidden = "n_kY d_kY";
export var imageAltSmall = "n_p9 d_k3";
export var imageAltNormal = "n_qb d_k3";
export var imageAltLarge = "n_qc d_k3";
export var imageTextSmall = "n_qd d_k4";
export var imageTextNormal = "n_qf d_k4";
export var imageTextLarge = "n_qg d_k4";